import React from "react";
import "./Contact.css";
import arial from "./pic/arial.jpg"

function Contact() {
    return (
        <div className="ContactContainer">
          <h1 className="title">Contact</h1>
            <img src={arial} className="arialPhoto" alt="none"/>
            <div className="bottomText">
              <div className="method">
                <h3>Email:</h3>
                <a href="mailto:info@lambtonscientific.com?subject=Web%20Form%20Inquiry">info@lambtonscientific.com</a>
              </div>
              <div className="method">
                <h3>Mail:</h3>
                <b>391 Vidal St. South</b>
                <b>Sarnia, Ontario</b>
                <b>N7T 2V3</b>
              </div>
              <div className="method">
                <h3>Phone:</h3>
                <b>Tel:519-344-4747</b>
                <b>Fax: 519-344-2350</b>
                <b>Toll Free: 1-888-344-8383</b>
              </div>
            </div>
        </div>
    );
  }
  
export default Contact;