import './App.css';
import React, {useState, useEffect } from 'react';
import Navbar from "./Navbar";
import Landing from "./Landing";
import About from "./About";
import Testing from "./Testing";
import Contact from "./Contact";
import Footer from "./Footer";
import Coc from "./Coc";

const App = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [sectionViewed, setSectionViewed] = useState({
    about: false,
    testing: false,
    contact: false
  });

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoaded(true);
    }, 1); // 1ms delay (can be increaed to simulate loading)

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    const sections = ['about', 'testing', 'contact', 'coc'];

    const handleIntersection = (entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setSectionViewed(prevState => ({
            ...prevState,
            [entry.target.id]: true
          }));
        }
      });
    };

    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.25 // fades when section 25% visible
    };

    const observer = new IntersectionObserver(handleIntersection, observerOptions);

    sections.forEach(section => {
      const target = document.getElementById(section);
      if (target) {
        observer.observe(target);
      }
    });

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    localStorage.setItem('sectionViewed', JSON.stringify(sectionViewed));
  }, [sectionViewed]);

  useEffect(() => {
    const storedSectionViewed = JSON.parse(localStorage.getItem('sectionViewed'));
    if (storedSectionViewed) {
      setSectionViewed(storedSectionViewed);
    }
  }, []);

  return (
    // for initial open fade
    <div className={`App ${isLoaded ? 'loaded' : ''}`}>
      <div className='navbarClass'>
        <Navbar/>
      </div>
        <section className='landingClass' id='landing'>
          <Landing/>
        </section>
        <Section id='about' isViewed={sectionViewed.about}>
          <About/>
        </Section>
        <Section id='testing' isViewed={sectionViewed.testing}>
          <Testing/>
        </Section>
        <Section id='contact' isViewed={sectionViewed.contact}>
          <Contact/>
        </Section>
        <Section id='coc' isViewed={sectionViewed.coc}>
          <Coc/>
        </Section>

      <hr className='divideLine'></hr>
      <div className='footerClass'>
        <Footer/>
      </div>    
    </div>
  );
}

// defining section
const Section = ({ id, isViewed, children }) => {
  return (
    // this is what changes the class so the css can fade in
    <section id={id} className={`scrollElement ${isViewed ? 'visible' : ''}`}>
      {children}
    </section>
  );
};

export default App;
