import React, { useState, useEffect } from "react";
import { Link } from 'react-scroll'
import "./Navbar.css";
import logo from "./pic/lsLogo.png"

function Navbar() {
    // Attach the scroll event listener when the component mounts
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
        // Clean up the scroll event listener when the component unmounts
        window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // State to hold the scroll position
    const [scrollPosition, setScrollPosition] = useState(0);

    // Function to handle scroll event and update scrollPosition state
    const handleScroll = () => {
        const currentPosition = window.scrollY;
        setScrollPosition(currentPosition);
    };

    const width = window.innerWidth;

    // this is to determine height of page so we can highlight the correct word in navbar
    const pageHeight = document.documentElement.scrollHeight;
    const aboutScroll = pageHeight * 0.17 // 17% of page height
    const testingScroll = pageHeight * 0.45 // 45% of page height
    const contactScroll = pageHeight * 0.66 // 66% of page height
    const cocScroll = pageHeight * 0.73 // 73% of page height

    // scroll color values for each section (non mobile)
    const about = (scrollPosition >= aboutScroll) && (scrollPosition < testingScroll) ? 'rgb(0, 0, 0)' : 'rgb(0, 0, 118)';
    const testing = (scrollPosition >= testingScroll) && (scrollPosition < contactScroll) ? 'rgb(0, 0, 0)' : 'rgb(0, 0, 118)';
    const contact = (scrollPosition  >= contactScroll) && (scrollPosition < cocScroll) ? 'rgb(0, 0, 0)' : 'rgb(0, 0, 118)';
    const coc = (scrollPosition >= cocScroll) ? 'rgb(0, 0, 0)' : 'rgb(0, 0, 118)';
    const textStyleAbout = {
        color: about,
    };
    const textStyleTesting = {
        color: testing,
    };
    const textStyleContact = {
        color: contact,
    };
    const textStyleCOC = {
        color: coc
    }

    return (
        <div className="NavbarContainer">
            <Link className="LSContain"
                to="landing"
                smooth={true}
                duration={500}
                spy={true}
            >
                <div className="imgContainer">
                    <img src={logo} className="imgLogoLS" alt="none"/>
                </div>
                <title className="titleName">Lambton Scientific</title>
            </Link>
            <div className="rightSideNav">
                <Link className="navButton"
                    to="about" // section name
                    smooth={true} // smooth scroll
                    duration={500} // 500ms
                    spy={true} // link is selected when scroll at traget postion
                    offset={-40} // for navbar height
                >
                    <b style={textStyleAbout}>About Us</b>
                </Link>
            
                <Link className="navButton"
                    to="testing"
                    smooth={true}
                    duration={500}
                    spy={true}
                    offset={-40} // for navbar height
                >
                    <b style={textStyleTesting}>Testing</b>
                </Link>
            
                <Link className="navButton"
                    to="contact"
                    smooth={true}
                    duration={500}
                    spy={true}
                    offset={-40} // for navbar height
                > 
                    <b style={textStyleContact}>Contact</b>
                </Link>

                <Link className="navButton"
                    to="coc"
                    smooth={true}
                    duration={500}
                    spy={true}
                    offset={-500} // large offset as to not "slam" into bottom of page
                >
                    <b style={textStyleCOC}>COC</b>
                </Link>
            </div>

        </div>
    );
  }
    
export default Navbar;