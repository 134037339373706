import React from "react";
import "./Landing.css";
import landingPic from "./pic/labphoto.jpeg";
import cred from "./pic/accreditation2024.pdf";

// opens acreditation pdf
function openPDF() {
    window.open(cred, "_blank");
}

function Landing() {
    return (
            <div className="LandingContainer">
                <img src={landingPic} className="landingBackground" alt="none"/>
                <div className="altBackground"></div>
                <div className="landingText">
                    <h1 className="landingHeader">A leader in analytical services</h1>
                    <h2 className="landingBody">
                        Full-service analytical laboratory, specializing
                        in wastewater and soil testing including:
                        <ul className="landingList">
                            <li>
                                A full service 
                                <span> </span> 
                                <a href="https://directory.cala.ca/">CALA</a>
                                <span> </span>
                                ISO 17025
                                <span> </span> 
                                <a onClick={openPDF} href={openPDF}>accredited lab</a>
                                <span> </span>
                            </li>
                            <li>Influent-to-effluent and soils environmental analytics</li>
                            <li>Waste characterization</li>
                            <li>Customized processing analytics</li>
                            <li>Internal and governmental reporting</li>
                        </ul>
                    </h2>
                </div>
            </div>
    );
  }
  
export default Landing;