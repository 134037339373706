import React from "react";
import "./About.css";
import lsLocation from "./pic/map.jpg"
import landingPic from "./pic/labPhoto.JPG";
import team from "./pic/team.jpeg"

function About() {
    return (
        <div className="AboutContainer">
            <h1 className="title">About Us</h1>
            <div className="sections">
                <img src={lsLocation} className="lsLocation" alt="none"/>
                <div className="rightSide">
                    <h1 className="aboutUsTitle">Location</h1>
                    <hr className="line"/>
                    <b className="aboutUsText">
                        A key element of Lambton Scientific’s commitment to our clients is our accessibility.
                        Located in Sarnia, Ontario’s Chemical Valley, Lambton Scientific’s strategic location puts us in close proximity to our clients:  some of the world’s largest petrochemical, synthetic rubber, and specialty chemicals manufacturers.
                        As well as being centrally located within an hour of Detroit, London and Flint, LS is a convenient 2-3 hour drive for our Toronto, Buffalo, and Toledo clients. 
                        Our local presence and accessible Chemical Valley location ensures that our clients have:
                        <ui>
                            <li>A well-situated and easily accessible resource for analytical services</li>
                            <li>Rapid, dependable turn-around of analytical services</li>
                            <li>Swift response to off-hours and emergency events and upsets</li>
                        </ui>
                    </b>
                </div>
            </div>
            <div className="sections">
                <div className="leftSide">
                    <h1 className="aboutUsTitle">Service</h1>
                    <hr className="lineLeft"/>
                    <b className="aboutUsTextLeft">
                        In 1988 Lambton Scientific began as a small analytical laboratory.  Since then we have grown in size, people, and technology, but our focus has remained consistent:
                        timely, accurate analytical services centred on customer needs and regulatory requirements.
                        Working in partnership with our clients, we ensure performance and data of the highest quality and dependability, and are fully equipped and staffed to meet the analytical, data management and service-related needs of our clients.
                    </b>
                </div>
                <img src={landingPic} className="lsLocationB" alt="none"/>
            </div>
            <div className="sections">
                <img src={team} className="lsLocationB" alt="none"/>
                <div className="rightSide">
                    <h1 className="aboutUsTitle">Team</h1>
                    <hr className="line"/>
                    <b className="aboutUsText">
                        Even with all of our instrumentation and analytical capabilities, Lambton Scientific ultimately relies on the quality and experience of its people.  Our highly trained staff have years of technology and industry training, along with extensive experience in analysis, which allows them to meet and exceed client expectations every day.
                        Our staff’s experience and knowledge are key in their ability to evaluate unique client requirements, and make recommendations or present alternate analytical options to resolve client process / environmental related issues.  Lambton Scientific's staff also provide client support for the interpretation of environmental regulations, operating Certificates of Approval, and have the knowledge to further explain what analytical results mean.
                    </b>
                </div>
            </div>
            
        </div>
    );
  }
  
export default About;