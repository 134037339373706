import React from "react";
import "./Footer.css";
import { Link } from 'react-scroll'
import logo from "./pic/lsLogo.png";

function Footer() {
    return (
      <div className="footerContainer">
        <div className="lsText">
          <b className="footText">@2006-2024 - Lambton Scientific. All rights reserved.</b>
          <a className="footText" href="https://www.flaticon.com/" title="icons">Icons by Freepik - Flaticon</a>
        </div>  

        <Link className="footerImg"
          to="landing"
          smooth={true}
          duration={500}
          spy={true}
        >
          <img src={logo} className="footerLogo" alt="none"/>
        </Link>

        <div className="textContainer">
          <b className="footText">Designed & created by: </b>
          <a className="myLink" href="https://aidanjames.ca/">@aidanjames</a>
        </div>
      </div>
    );
  }
  
export default Footer;