import doc from "./pic/lsCOCpdf.pdf";
import ex from "./pic/lsCOCxls.xls";
import pdf from "./pic/pdf-file.png";
import xls from "./pic/xls-file.png";
import "./Coc.css"

// opens pdf version
function openDoc() {
    window.open(doc, "_blank");
}
// opens xls version (download)
function openEx() {
    window.open(ex, "-black");
}

function Coc() {
    return (
        <div className="Coc">
            <b>Download our Chain of Custodies: </b>
            <img onClick={openEx} src={xls} alt="xls"/>
            <img onClick={openDoc} src={pdf} alt="pdf"/>
        </div>
    );
}

export default Coc