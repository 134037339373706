import React from "react";
import "./Testing.css";
import metal from "./pic/metals.png";
import organic from "./pic/organics.png";
import TCLP from "./pic/TCLP.png";
import conv from "./pic/conventionals.png";

function Testing() {
    return (
        <div className="testingContainer">
            <h1 className="title">Testing</h1>

            <div className="cardsContainer">
              <button className="flipCard">
                <div className="flipCardInner">
                  <div className="flipCardFront">
                    <h1 className="cardTitle">Metals</h1>
                    <img src={metal} className="testIcon" alt="none"/>
                  </div>
                  <div class="flipCardBack">
                    <h1>Metals capabilities from sub-ppb to percent levels via:</h1>
                    <ul>
                      <li>ICP (Inductively Coupled Argon Plasma, Atomic Emission Spectroscopy)</li>
                      <li>Hydrides</li>
                      <li>Cold Vapor AAS (Mercury)</li>
                      <li>HTHP Microwave assisted digest</li>
                    </ul>
                  </div>
                </div>
            </button>

            <button className="flipCard">
                <div className="flipCardInner">
                  <div className="flipCardFront">
                    <h1 className="cardTitle">Organics</h1>
                    <img src={organic} className="testIconO" alt="none"/>
                  </div>
                  <div class="flipCardBack">
                    <ul>
                      <li>Purge and Trap GC/MS (volatile Organic Compounds)</li>
                      <li>Ultra trace confirmation and quantification by state-of-the-art QQQ-GC/MS/MS</li>
                      <li>USEPA and MISA organic parameters/packages</li>
                      <li>Rapid scan for the determination of benzene, toluene, ethyl benzene, xylenes and other organic compounds</li>
                      <li>GC/MS Extraction (Semi-Volatile Organic Compounds)</li>
                      <li>Headspace analysis</li>
                      <li>Total Petroleum Hydrocarbons F1 - F4 by GC/FID</li>
                    </ul>
                  </div>
                </div>
            </button>

            <button className="flipCard">
                <div className="flipCardInner">
                  <div className="flipCardFront">
                    <h1 className="cardTitle">Conventionals</h1>
                    <img src={conv} className="testIcon" alt="none"/>
                  </div>
                  <div class="flipCardBack">
                    <ul>
                      <li>Physical properties testing</li>
                      <li>Inorganic parameters</li>
                      <li>Petroleum related physical and chemical testing</li>
                      <li>UV-Vis Spectroscopy</li>
                      <li>TIC/TOC carbon</li>
                      <li>IC Anions</li>
                      <li>SFA, TKN / Phenolics</li>
                      <li>Ph, BOD, COD, NH3, Solids, etc. analysis</li>
                    </ul>
                  </div>
                </div>
            </button>

            <button className="flipCard">
                <div className="flipCardInner">
                  <div className="flipCardFront">
                    <h1 className="cardTitle">Waste Characterization</h1>
                    <img src={TCLP} className="testIcon" alt="none"/>
                  </div>
                  <div class="flipCardBack">
                    <ul>
                      <li>Ontario Regulation 347 - Toxic Characteristic Leaching Procedure (TCLP)</li>
                      <li>Ontario Regulation 461 - Land Disposal Regular (LDR)</li>
                      <li>Sewer-Use By-Laws (Storm, Sanitary, etc)</li>
                      <li>Ontario Municipal and Industrial Strategy for Abatement (MISA ATG)</li>
                      <li>US EPA SW-846</li>
                    </ul>
                  </div>
                </div>
            </button>
          </div>
      </div>
    );
  }
  
export default Testing;